export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_ERROR_CLEAR = "LOGIN_ERROR_CLEAR";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_PLANNING_YEAR = "UPDATE_USER_PLANNING_YEAR";

/* Product */
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_PRODUCT_PERMISSIONS = "GET_PRODUCT_PERMISSIONS";

/* Engagement */
export const SCORE_REQUEST = "SCORE_REQUEST";
export const DRIVER_OVERVIEW_REQUEST = "DRIVER_OVERVIEW_REQUEST";

export const TRENDS_DATA_REQUEST = "TRENDS_DATA_REQUEST";
export const PARTICIPANTS_REQUEST = "PARTICIPANTS_REQUEST";
export const DEPARTMENT_OVERVIEW_REQUEST = "DEPARTMENT_OVERVIEW_REQUEST";
export const GET_TIME_RANGE_90_DAYS = "GET_TIME_RANGE_90_DAYS";
export const GET_TIME_RANGE_QUATERLY = "GET_TIME_RANGE_QUATERLY";

export const ADD_INDEX_ONE = "ADD_INDEX_ONE";
export const SUBSTRACT_INDEX_ONE = "SUBSTRACT_INDEX_ONE";

export const MANAGER_PERFORMANCE_REQUEST = "MANAGER_PERFORMANCE_REQUEST";
export const INSIGHTS_REQUEST = "INSIGHTS_REQUEST";

export const SURVEY_REQUEST = "SURVEY_REQUEST";

export const GET_SURVEY_LIST = "GET_SURVEY_LIST";
export const CREATING_SURVEY = "CREATING_SURVEY";
export const SURVEY_CREATED_NOTIF = "SURVEY_CREATED_NOTIF";
export const UPDATE_SURVEY = "UPDATE_SURVEY";
export const UPDATE_SURVEY_LIST = "UPDATE_SURVEY_LIST";
export const UPDATE_SURVEY_LIST_IDS = "UPDATE_SURVEY_LIST_IDS";
export const UPDATE_QUESTIONS_LIST = "UPDATE_QUESTIONS_LIST";
export const UPDATE_SURVEY_QUESTIONS_LIST_IDS =
  "UPDATE_SURVEY_QUESTIONS_LIST_IDS";
export const UPDATE_SURVEY_QUESTION_LIST = "UPDATE_SURVEY_QUESTION_LIST";
export const SET_ACTIVE_SURVEY_CARD = "SET_ACTIVE_SURVEY_CARD";
export const DELETE_SURVEY = "DELETE_SURVEY";
export const DUPLICATE_SURVEY = "DUPLICATE_SURVEY";
export const GET_ALL_QUESTIONS = "GET_ALL_QUESTIONS";
export const UPDATE_DRIVERS_LIST = "UPDATE_DRIVERS_LIST";

export const GET_SURVEY_QUESTIONS_LIST = "GET_SURVEY_QUESTIONS_LIST";
export const UPDATE_SURVEY_QUESTIONS_LIST = "UPDATE_SURVEY_QUESTIONS_LIST";

export const CREATE_SURVEY = "CREATE_SURVEY";
export const UPDATE_CREATE_SURVEY = "UPDATE_CREATE_SURVEY";

export const GET_MESSAGE_LIST_REQUEST = "GET_MESSAGE_LIST_REQUEST";
export const UPDATE_LIST_BY = "UPDATE_LIST_BY";
export const GET_SCORE_CARD_FILTER = "GET_SCORE_CARD_FILTER";

export const GET_DEPARTMENTS_REQUEST = "GET_DEPARTMENTS_REQUEST";
export const DEPARTMENT_SCORE_REQUEST = "DEPARTMENT_SCORE_REQUEST";
export const DEPARTMENT_TRENDS_REQUEST = "DEPARTMENT_TRENDS_REQUEST";
export const DEPARTMENT_INSIGHTS_REQUEST = "DEPARTMENT_INSIGHTS_REQUEST";
export const DEPARTMENT_DRIVER_OVERVIEW_REQUEST =
  "DEPARTMENT_DRIVER_OVERVIEW_REQUEST";
export const DEPARTMENT_PARTICIPANTS_REQUEST =
  "DEPARTMENT_PARTICIPANTS_REQUEST";
export const DEPARTMENT_MANAGER_PERFORMANCE_REQUEST =
  "DEPARTMENT_MANAGER_PERFORMANCE_REQUEST";
export const GET_DEPARTMENT_FILTERS_REQUEST = "GET_DEPARTMENT_FILTERS_REQUEST";
export const GET_DEPARTMENT_MESSAGE_LIST_REQUEST =
  "GET_DEPARTMENT_MESSAGE_LIST_REQUEST";

export const GET_FILTERS_REQUEST = "GET_FILTERS_REQUEST";
export const POST_MESSAGE_REQUEST = "POST_MESSAGE_REQUEST";
export const GET_TEXT_RESPONSES = "GET_TEXT_RESPONSES";
export const SHOW_CONVERSATION_REQUEST = "SHOW_CONVERSATION_REQUEST";
export const GET_SINGLE_MESSAGE_CONTENT = "GET_SINGLE_MESSAGE_CONTENT";
export const GET_UPDATED_CONVERSATION = "GET_UPDATED_CONVERSATION";
export const UPDATE_PAGE_TITLE = "UPDATE_PAGE_TITLE";
export const GET_ALL_ACCOUNT_ADMINS = "GET_ALL_ACCOUNT_ADMINS";
export const USER_SWITCHED = "USER_SWITCHED";

export const HEATMAP_OVERVIEW_REQUEST = "HEATMAP_OVERVIEW_REQUEST";
export const GET_HEATMAP_FILTERS_REQUEST = "GET_HEATMAP_FILTERS_REQUEST";

export const SURVEY_SCHEDULE = "SURVEY_SCHEDULE";
export const GET_QUESTIONS_LIST = "GET_QUESTIONS_LIST";
export const GET_QUESTIONS_FROM_DB = "GET_QUESTIONS_FROM_DB";
export const ADD_LIBRARY_QUESTIONS = "ADD_LIBRARY_QUESTIONS";
export const SCHEDULE_LIST = "SCHEDULE_LIST";
export const DRIVER_LIST = "DRIVER_LIST";
export const SURVEY_MINIMUM_EMPLOYEES_LIMIT = 3;

export const SET_TEMPLATES = "SET_TEMPLATES";
export const CLEAR_TEMPLATES = "CLEAR_TEMPLATES";

export const CUSTOM_QUESTION = "CUSTOM_QUESTION";

export const UPDATE_GLASSDOOR_URL = "UPDATE_GLASSDOOR_URL";

export const GET_SURVEY_PARTICIPANTS = "GET_SURVEY_PARTICIPANTS";

/* FOR MANAGER */

export const SUBNAVBAR_ACTIVE_MENU = "SUBNAVBAR_ACTIVE_MENU";
export const GET_MY_FEEDBACK_LIST = "GET_MY_FEEDBACK_LIST";
export const GET_TEAM_FEEDBACK_LIST = "GET_TEAM_FEEDBACK_LIST";
export const GET_FEEDBACK_QUESTIONS = "GET_FEEDBACK_QUESTIONS";
export const FEEDBACK_SURVEY_STATUS = "FEEDBACK_SURVEY_STATUS";
export const NAVIGATE_QUESTION = "NAVIGATE_QUESTION";
export const ADD_TEMP_RESPONSES = "ADD_TEMP_RESPONSES";

/* Superlist */

export const ADD_NEW_TODO = "ADD_NEW_TODO";
export const GET_TODO_ITEMS = "GET_TODO_ITEMS";
export const GET_UPCOMING_ACTIVITIES = "GET_UPCOMING_ACTIVITIES";
export const HIDE_COMPLETED_ITEMS = "HIDE_COMPLETED_ITEMS";
export const COMPLETED_ITEMS_ID = "COMPLETED_ITEMS_ID";
export const SHOW_COMPLETED_ITEMS = "SHOW_COMPLETED_ITEMS";
export const GET_FEEDBACK_STATUS = "GET_FEEDBACK_STATUS";
export const FLUSH_COMPLETED_ITEMS = "FLUSH_COMPLETED_ITEMS";

/* SPECIFIC PULSE FOR MANAGER AND USER BOTH */
export const GET_PULSE_DETAILS = "GET_PULSE_DETAILS";

export const ADD_COMMENTS_ON_RESPONSE = "ADD_COMMENTS_ON_RESPONSE";
export const GET_PERFORMANCE_CHECKIN_DETAILS =
  "GET_PERFORMANCE_CHECKIN_DETAILS";
export const UPDATE_PULSE_STATUS = "UPDATE_PULSE_STATUS";

/* One On One */

export const GET_OOO_UPCOMING_LIST = "GET_OOO_UPCOMING_LIST";
export const GET_OOO_TODAY_LIST = "GET_OOO_TODAY_LIST";
export const GET_OOO_TOMORROW_LIST = "GET_OOO_TOMORROW_LIST";
export const GET_OOO_THIS_WEEK_UPCOMING_LIST =
  "GET_OOO_THIS_WEEK_UPCOMING_LIST";
export const GET_OOO_NEXT_WEEK_UPCOMING_LIST =
  "GET_OOO_NEXT_WEEK_UPCOMING_LIST";
export const GET_OOO_PAST_LIST = "GET_OOO_PAST_LIST";
export const GET_OOO_LIST_POLLING_DATA = "GET_OOO_LIST_POLLING_DATA";
export const GET_REPORTEES = "GET_REPORTEES";
export const DELETE_REPORTEES = "DELETE_REPORTEES";
export const UPDATE_REPORTEES_DETAILS_SUCCESS =
  "UPDATE_REPORTEES_DETAILS_SUCCESS";
export const CURRENT_OOO = "CURRENT_OOO";
export const GET_ONE_ON_ONE_REASONS = "GET_ONE_ON_ONE_REASONS";
export const ADD_TO_ONE_ON_ONE_LIST_DETAILS = "ADD_TO_ONE_ON_ONE_LIST_DETAILS";
export const ADD_TO_ONE_ON_ONE_LIST_IDS = "ADD_TO_ONE_ON_ONE_LIST_IDS";
export const ADD_TO_ONE_ON_ONE_SHOW_LIST_IDS =
  "ADD_TO_ONE_ON_ONE_SHOW_LIST_IDS";
export const ADD_TO_VISITED_ONE_ON_ONES = "ADD_TO_VISITED_ONE_ON_ONES";
export const STOP_POLLING_ONE_ON_ONE = "STOP_POLLING_ONE_ON_ONE";

/* Talking Points */
export const GET_TALKING_POINTS = "GET_TALKING_POINTS";
export const ADD_TALKING_POINT = "ADD_TALKING_POINT";
export const UPDATE_TALKING_POINT = "UPDATE_TALKING_POINT";
export const DELETE_TALKING_POINT = "DELETE_TALKING_POINT";
export const GET_OTHER_PARTY_TALKING_POINTS = "GET_OTHER_PARTY_TALKING_POINTS";
export const GET_TALKING_POINTS_TEMPLATES = "GET_TALKING_POINTS_TEMPLATES";
export const FILTER_TEMPLATE_LIST = "FILTER_TEMPLATE_LIST";

/* Followup items */

export const GET_FOLLOWUP_ITEMS = "GET_FOLLOWUP_ITEMS";
export const ADD_FOLLOWUP_ITEMS = "ADD_FOLLOWUP_ITEMS";
export const UPDATE_FOLLOWUP_ITEMS = "UPDATE_FOLLOWUP_ITEMS";
export const DELETE_FOLLOWUP_ITEM = "DELETE_FOLLOWUP_ITEM";
export const TOGGLE_PAGINATE_ITEMS = "TOGGLE_PAGINATE_ITEMS";

/* Notes */

export const GET_NOTES = "GET_NOTES";
export const ADD_NOTES = "ADD_NOTES";
export const ADD_TEMPLATE_POINT_TO_SHARED_NOTES =
  "ADD_TEMPLATE_POINT_TO_SHARED_NOTES";
export const UPDATE_SHARED_NOTES = "UPDATE_SHARED_NOTES";
export const UPDATE_USER_PRIVATE_NOTES = "UPDATE_USER_PRIVATE_NOTES";
export const SHARED_NOTES_SAVE_STATUS = "SHARED_NOTES_SAVE_STATUS";
export const PRIVATE_NOTES_SAVE_STATUS = "PRIVATE_NOTES_SAVE_STATUS";

/* suggested items */

export const GET_SUGGESTED_ITEMS = "GET_SUGGESTED_ITEMS";

export const CHANGE_SURVEY_START = "CHANGE_SURVEY_START";

/* marketing leads */

export const ADD_MARKETING_LEADS = "ADD_MARKETING_LEADS";

/* referrer location needed if redirected to login page */

export const GET_REFERRER_LOCATION = "GET_REFERRER_LOCATION";

export const GET_TALKING_POINTS_FROM_ANSWERS =
  "GET_TALKING_POINTS_FROM_ANSWERS";

/* calender items */

export const GET_CALENDER_ITEMS = "GET_CALENDER_ITEMS";
export const HANDLE_CHECKED_EVENTS_IDS = "HANDLE_CHECKED_EVENTS_IDS";
export const EMPTY_CALENDER_STATE = "EMPTY_CALENDER_STATE";
export const SET_SYNCING_STATUS = "SET_SYNCING_STATUS";
export const CALENDAR_TIMER_STATUS = "CALENDAR_TIMER_STATUS";

/* performance rating */

export const GET_COLLECT_PERFORMANCE_RATING = "GET_COLLECT_PERFORMANCE_RATING";
export const UPDATE_COLLECT_PERFORMANCE_RATING =
  "UPDATE_COLLECT_PERFORMANCE_RATING";
export const COLLECT_PERFORMANCE_DISPLAY_STATE =
  "COLLECT_PERFORMANCE_DISPLAY_STATE";
export const GET_SHOW_PEFORMANCE_RATING = "GET_SHOW_PEFORMANCE_RATING";
export const UPDATE_SHOW_PERFORMANCE_RATING = "UPDATE_SHOW_PERFORMANCE_RATING";

/* productivity */

export const GET_COLLECT_PRODUCTIVITY = "GET_COLLECT_PRODUCTIVITY";
export const UPDATE_COLLECT_PRODUCTIVIY = "UPDATE_COLLECT_PRODUCTIVIY";
export const GET_SHOW_PRODUCTIVITY_RATING = "GET_SHOW_PRODUCTIVITY_RATING";
export const COLLECT_PRODUCTIVITY_DISPLAY_STATE =
  "COLLECT_PRODUCTIVITY_DISPLAY_STATE";

/* Action Items */

export const GET_ACTION_ITEMS = "GET_ACTION_ITEMS";
export const UPDATE_ACTION_ITEMS_ON_VIEW_MORE =
  "UPDATE_ACTION_ITEMS_ON_VIEW_MORE";
export const UPDATE_ACTION_ITEMS_ON_MOUNT = "UPDATE_ACTION_ITEMS_ON_MOUNT";
export const ADD_ACTION_ITEM = "ADD_ACTION_ITEM";
export const UPDATE_ACTION_ITEM = "UPDATE_ACTION_ITEM";
export const CREATE_ACTION_ITEM = "CREATE_ACTION_ITEM";
export const DELETE_ACTION_ITEM = "DELETE_ACTION_ITEM";
export const REQUEST_INITIATED = "REQUEST_INITIATED";
export const GET_COMPLETED_ACTION_ITEMS = "GET_COMPLETED_ACTION_ITEMS";
export const COMPLETED_ACTION_ITEMS_COUNT = "COMPLETED_ACTION_ITEMS_COUNT";
export const UPDATE_VIEW_MORE = "UPDATE_VIEW_MORE";

/* onboarding */

export const ADD_ONBOARDING_STEP_DETAILS = "ADD_ONBOARDING_STEP_DETAILS";
export const UPDATE_ONBOARDING_STEP_DETAILS = "UPDATE_ONBOARDING_STEP_DETAILS";
export const HIDE_TOUR_COMPONENT = "HIDE_TOUR_COMPONENT";
export const SET_CURRENT_COMPONENT = "SET_CURRENT_COMPONENT";
export const SHOW_INCOMPLETE_STATE_TOUR_COMPONENT =
  "SHOW_INCOMPLETE_STATE_TOUR_COMPONENT";

/* employee data import */
export const EMPLOYEE_DATA_IMPORT_SUCCESS = "EMPLOYEE_DATA_IMPORT_SUCCESS";
export const STOP_POLLING_EMPLOYEE_IMPORT = "STOP_POLLING_EMPLOYEE_IMPORT";
export const START_POLLING_EMPLOYEE_IMPORT = "START_POLLING_EMPLOYEE_IMPORT";

/* manage employee */
export const GET_EMPLOYEES_LIST_SUCCESS = "GET_EMPLOYEES_LIST_SUCCESS";
export const GET_MANAGERS_LIST_SUCCESS = "GET_MANAGERS_LIST_SUCCESS";
export const GET_DEPARTMENTS_LIST_SUCCESS = "GET_DEPARTMENTS_LIST_SUCCESS";
export const UPDATE_EMPLOYEE_DETAILS = "UPDATE_EMPLOYEE_DETAILS";
export const ADD_NEW_DEPARTMENT = "ADD_NEW_DEPARTMENT";
export const ADD_NEW_EMPLOYEES = "ADD_NEW_EMPLOYEES";
export const OPEN_DEPARTMENT_MODAL = "OPEN_DEPARTMENT_MODAL";
export const CREATE_DEPARTMENT_NOTIF = "CREATE_DEPARTMENT_NOTIF";
export const DELETE_DEPARTMENT_NOTIF = "DELETE_DEPARTMENT_NOTIF";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const SAVE_DEPARTMENT_NOTIF = "SAVE_DEPARTMENT_NOTIF";
export const SAVE_DEPARTMENT = "SAVE_DEPARTMENT";
export const INACTIVATE_EMPLOYEE = "INACTIVATE_EMPLOYEE";
export const UPDATE_BILLING_STATUS = "UPDATE_BILLING_STATUS";
export const SET_IDP_TRACK_POSITIONS = "SET_IDP_TRACK_POSITIONS";
/* manage drivers */
export const CREATE_DRIVER_NOTIF = "CREATE_DRIVER_NOTIF";
export const DELETE_DRIVER_NOTIF = "DELETE_DRIVER_NOTIF";
export const SAVE_DRIVER_NOTIF = "SAVE_DRIVER_NOTIF";
export const ADD_NEW_DRIVER = "ADD_NEW_DRIVER";
export const GET_DRIVERS_LIST_SUCCESS = "GET_DRIVERS_LIST_SUCCESS";
export const DELETE_DRIVER = "DELETE_DRIVER";
export const SAVE_DRIVER = "SAVE_DRIVER";

/* Billing */
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const GET_BILLING_PLANS = "GET_BILLING_PLANS";
export const APPSUMO_CREATE_MESSAGE_KEY = "APPSUMO_CREATE_MESSAGE_KEY";
export const APPSUMO_LOADING_START = "APPSUMO_LOADING_START";
export const APPSUMO_LOADING_COMPLETE = "APPSUMO_LOADING_COMPLETE";
export const APPSUMO_REDEEM_SUCCESS = "APPSUMO_REDEEM_SUCCESS";
export const APPSUMO_REDEEM_ERROR = "APPSUMO_REDEEM_ERROR";

/* calendar less flow */

export const UPDATE_CALENDAR_ACCESS_CHECK = "UPDATE_CALENDAR_ACCESS_CHECK";

/* Goals */
export const GET_KEY_RESULTS_IDS_LIST = "GET_KEY_RESULTS_IDS_LIST";
export const GET_GOALS_IDS_LIST = "GET_GOALS_IDS_LIST";
export const UPDATE_GOAL_IN_LISTING = "UPDATE_GOAL_IN_LISTING";
export const MOVE_GOAL_FROM_ONE_CYCLE_TO_ANOTHER =
  "MOVE_GOAL_FROM_ONE_CYCLE_TO_ANOTHER";
export const REMOVE_GOAL_ID_FROM_LIST = "REMOVE_GOAL_ID_FROM_LIST";
export const ADD_GOAL_ID_TO_LIST = "ADD_GOAL_ID_TO_LIST";
export const GET_GOALS_OBJECT_LIST = "GET_GOALS_OBJECT_LIST";
export const CLEAN_INITIALLY_VISIBLE_GOALS_OBJECT_LIST =
  "CLEAN_INITIALLY_VISIBLE_GOALS_OBJECT_LIST";
export const REMOVE_ALIGNED_PARENT_FROM_GOAL =
  "REMOVE_ALIGNED_PARENT_FROM_GOAL";
export const GET_KEY_RESULTS_LIST = "GET_KEY_RESULTS_LIST";
export const GET_GOALS_LIST = "GET_GOALS_LIST";

export const GET_GOAL_ACTIVITIES_LIST = "GET_GOAL_ACTIVITIES_LIST";
export const GET_GOALS_SHOW = "GET_GOALS_SHOW";
export const GET_GOAL_SHOW = "GET_GOAL_SHOW";
export const ADD_GOALS_ACTIVITY = "ADD_GOALS_ACTIVITY";
export const UPDATE_EDIT_GOAL_ACTIVITY_DATA = "UPDATE_EDIT_GOAL_ACTIVITY_DATA";
export const DELETE_DRAWER_ACTIVITY_MESSAGE_DATA =
  "DELETE_DRAWER_ACTIVITY_MESSAGE_DATA";
export const UPDATE_KEY_RESULT_PROGRESS = "UPDATE_KEY_RESULT_PROGRESS";
export const GET_GOAL_DRAWER_ACTIVITIES = "GET_GOAL_DRAWER_ACTIVITIES";
export const UPDATE_GOAL_DRAWER_ACTVITIES = "UPDATE_GOAL_DRAWER_ACTVITIES";
export const RESET_GOAL_ACTVITIES_DRAWER = "RESET_GOAL_ACTVITIES_DRAWER";
export const UPDATE_GOAL_ACTIVITIES_COMMENTS_DRAFT =
  "UPDATE_GOAL_ACTIVITIES_COMMENTS_DRAFT";
export const UPDATE_GOAL_ACTIVITIES_CHECKIN_DRAFT =
  "UPDATE_GOAL_ACTIVITIES_CHECKIN_DRAFT";

export const GET_GOALS_FILTER_LIST = "GET_GOALS_FILTER_LIST";
export const ADD_GOAL_FILTER = "ADD_GOAL_FILTER";
export const ADD_GOAL_FILTER_TO_TOP = "ADD_GOAL_FILTER_TO_TOP";
export const REMOVE_GOAL_FILTER = "REMOVE_GOAL_FILTER";
export const SET_GOAL_FILTER_LOADING = "SET_GOAL_FILTER_LOADING";
export const RE_ORDER_GOALS_FILTER = "RE_ORDER_GOALS_FILTER";
export const GET_USER_GOALS_CYCLE_LIST = "GET_USER_GOALS_CYCLE_LIST";
export const SET_GOAL_CYCLES_LOADING = "SET_GOAL_CYCLES_LOADING";
export const GET_USER_SPECIFIC_CYCLE_LIST = "GET_USER_SPECIFIC_CYCLE_LIST";
export const GET_USER_SPECIFIC_CYCLE_IDS_OBJECT_LIST =
  "GET_USER_SPECIFIC_CYCLE_IDS_OBJECT_LIST";
export const GET_GOALS_CYCLE_STATE_OBJECT_LIST =
  "GET_GOALS_CYCLE_STATE_OBJECT_LIST";
export const GET_GOALS_CYCLE_IDS_OBJECT_LIST =
  "GET_GOALS_CYCLE_IDS_OBJECT_LIST";
export const GET_GOALS_CYCLE_UPDATED_LIST = "GET_GOALS_CYCLE_UPDATED_LIST";
export const GET_GOALS_BY_CYCLE = "GET_GOALS_BY_CYCLE";
export const GET_COMMON_CYCLE_LIST = "GET_COMMON_CYCLE_LIST";
export const SET_ALL_CYCLES = "SET_ALL_CYCLES";

export const INTEGRATION_CONNECTOR_LIST = "INTEGRATION_CONNECTOR_LIST";
export const INTEGRATION_CONNECTOR_DETAIL = "INTEGRATION_CONNECTOR_DETAIL";
export const INTEGRATION_CONNECTOR_METRIC = "INTEGRATION_CONNECTOR_METRIC";
export const INTEGRATION_CONNECTOR_VERIFY_INPUT =
  "INTEGRATION_CONNECTOR_VERIFY_INPUT";
export const INTEGRATION_QUERY_BLOCK_SUCCESS =
  "INTEGRATION_QUERY_BLOCK_SUCCESS";

export const GET_GOALS_TYPE_FILTERS = "GET_GOALS_TYPE_FILTERS";
export const UPDATE_GOAL_REQUEST_STATE = "UPDATE_GOAL_REQUEST_STATE";
export const UPDATE_GOAL_IDS_LIST = "UPDATE_GOAL_IDS_LIST";
export const UPDATE_GOAL_CYCLE_DATA = "UPDATE_GOAL_CYCLE_DATA";
export const UPDATE_GOAL_CYCLE_LOADING = "UPDATE_GOAL_CYCLE_LOADING";
export const UPDATE_GOAL_CYCLE_ERROR = "UPDATE_GOAL_CYCLE_ERROR";
export const UPDATE_GOAL_ID_LOADING = "UPDATE_GOAL_ID_LOADING";
export const UPDATE_GOAL = "UPDATE_GOAL";
export const DELETE_GOAL = "DELETE_GOAL";
export const GET_PROGRESS_TYPE_LIST = "GET_PROGRESS_TYPE_LIST";
export const ADD_KEY_RESULT_ID = "ADD_KEY_RESULT_ID";
export const UPDATE_TREE_STRUCTURE_NODE = "UPDATE_TREE_STRUCTURE_NODE";
export const GET_GOALS_ALIGNMENT_LIST = "GET_GOALS_ALIGNMENT_LIST";
export const UPDATE_GOALS_OBJECT_LIST = "UPDATE_GOALS_OBJECT_LIST";
export const UPDATE_NEW_GOALS_DATA = "UPDATE_NEW_GOALS_DATA";
export const UPDATE_GOAL_PROGRESS = "UPDATE_GOAL_PROGRESS";
export const DELETE_KEY_RESULT_ARRAY = "DELETE_KEY_RESULT_ARRAY";
export const DELETE_GOAL_ID_LIST = "DELETE_GOAL_ID_LIST";
export const UPDATE_GOAL_PARENT_NODE = "UPDATE_GOAL_PARENT_NODE";
export const DELETE_KEY_RESULT_ID = "DELETE_KEY_RESULT_ID";
export const UPDATE_KEY_RESULT_ID_LIST = "UPDATE_KEY_RESULT_ID_LIST";
export const UPDATE_GOALS_CHILDREN_LIST = "UPDATE_GOALS_CHILDREN_LIST";
export const UPDATE_ACTIVE_TIMELINE_VIEW = "UPDATE_ACTIVE_TIMELINE_VIEW";
export const UPDATE_GOAL_SETTINGS = "UPDATE_GOAL_SETTINGS";
export const GOALTYPE_SEARCH_VALUE = "GOALTYPE_SEARCH_VALUE";
export const DELETE_GOALS_OBJECT = "DELETE_GOALS_OBJECT";
export const UPDATE_GOAL_IDS_BY_PRIORITY = "UPDATE_GOAL_IDS_BY_PRIORITY";
export const SYNCING_PROJECTS_FROM_GOOGLE_SHEET =
  "SYNCING_PROJECTS_FROM_GOOGLE_SHEET";
export const CLEAN_OLD_PARENT_ID_FROM_CYCLE_GOAL_LIST =
  "CLEAN_OLD_PARENT_ID_FROM_CYCLE_GOAL_LIST";
export const ADD_PARENT_ID_TO_CYCLE_GOAL_LIST =
  "ADD_PARENT_ID_TO_CYCLE_GOAL_LIST";
export const SET_SAVE_AND_CREATE_NEXT_GOAL_ID =
  "SET_SAVE_AND_CREATE_NEXT_GOAL_ID";
export const RESET_SAVE_AND_CREATE_NEXT_GOAL_ID =
  "RESET_SAVE_AND_CREATE_NEXT_GOAL_ID";
export const UPDATE_CYCLE_PAGINATION_REMAINING =
  "UPDATE_CYCLE_PAGINATION_REMAINING";
export const UPDATE_CYCLE_SHOW_PROGRESS_BREAKDOWN =
  "UPDATE_CYCLE_SHOW_PROGRESS_BREAKDOWN";

export const SET_MILESTONE_CYCLES = "SET_MILESTONE_CYCLES";
export const SET_OKR_PROGRESS_BREAKDOWN = "SET_OKR_PROGRESS_BREAKDOWN";
export const SET_SELECTED_TIMELINE_CYCLE = "SET_SELECTED_TIMELINE_CYCLE";
export const SET_IS_FETCHING_PROGRESS_BREAKDOWN =
  "SET_IS_FETCHING_PROGRESS_BREAKDOWN";
export const SET_PROGRESS_BREAKDOWN_FETCHED = "SET_PROGRESS_BREAKDOWN_FETCHED";

// Goals filter by attributes
export const UPDATE_CYCLE_FILTER_ATTRIBUTES = "UPDATE_CYCLE_FILTER_ATTRIBUTES";
export const REMOVE_CYCLE_FILTERS = "REMOVE_CYCLE_FILTERS";
export const UPDATE_PAGE_FILTER = "UPDATE_PAGE_FILTER";
export const REMOVE_PAGE_FILTER = "REMOVE_PAGE_FILTER";
export const UPDATE_CYCLE_LOADING_MAPPING = "UPDATE_CYCLE_LOADING_MAPPING";

/* MetaData constants */
export const FETCH_META_DATA_DETAILS = "FETCH_META_DATA_DETAILS";
export const FETCH_META_DATA_DETAILS_FAIL = "FETCH_META_DATA_DETAILS_FAIL";

/* Performance Reviews */
export const UPDATE_SEARCH_PEERS_RESULT = "UPDATE_SEARCH_PEERS_RESULT";
export const GET_REVIEW_CYCLE_STATUS = "GET_REVIEW_CYCLE_STATUS";
export const RESET_REVIEW_CYCLES = "RESET_REVIEW_CYCLES";
export const GET_MANAGER_AND_DIRECT_REVIEWS = "GET_MANAGER_AND_DIRECT_REVIEWS";
export const GET_DIRECT_REPORT_GOAL_DATA = "GET_DIRECT_REPORT_GOAL_DATA";
export const GET_REVIEWEES_LIST = "GET_REVIEWEES_LIST";
export const SET_CALIBRATIONS_VIEW_DATA = "SET_CALIBRATIONS_VIEW_DATA";
export const GET_MANAGER_APPROVE_NOMINATIONS =
  "GET_MANAGER_APPROVE_NOMINATIONS";
export const GET_APPROVE_PEER_LIST = "GET_APPROVE_PEER_LIST";

/* Performance Reviews Management */
export const GET_REVIEW_CYCLE_LIST = "GET_REVIEW_CYCLE_LIST";
export const GET_ARCHIVED_REVIEW_CYCLE_LIST = "GET_ARCHIVED_REVIEW_CYCLE_LIST";
export const GET_REVIEWS_FOR_ME = "GET_REVIEWS_FOR_ME";
export const GET_NOTIFICATION_LAUNCHED_FOR_PHASE =
  "GET_NOTIFICATION_LAUNCHED_FOR_PHASE";
export const GET_REVIEWEE_TABLE_DATA = "GET_REVIEWEE_TABLE_DATA";
export const GET_CALIBRATION_TABLE_DATA = "GET_CALIBRATION_TABLE_DATA";
export const SET_SELECTED_CALIBRATION_QUESTION =
  "SET_SELECTED_CALIBRATION_QUESTION";
export const GET_CALIBRATABLE_QUESTIONS = "GET_CALIBRATABLE_QUESTIONS";

/* Inviting Employee */
export const EMPLOYEE_INVITE_SUCCESS = "EMPLOYEE_INVITE_SUCCESS";

export const CHANGE_GOALS_FILTER = "CHANGE_GOALS_FILTER";

/* Settings */
export const UPDATE_ACCOUNT_SETTINGS = "UPDATE_ACCOUNT_SETTINGS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const GET_NOTIFICATION_STATUS = "GET_NOTIFICATION_STATUS";
export const UPDATE_SSO_SETTING_STATUS = "UPDATE_SSO_SETTING_STATUS";
export const UPDATE_COMPANY_1ON1_SETTINGS = "UPDATE_COMPANY_1ON1_SETTINGS";

// Survey Analysis Action types
export const GET_SURVEY_ANALYSIS = "GET_SURVEY_ANALYSIS";
export const GET_DRIVERS_AND_QUES = "GET_DRIVERS_AND_QUES";
export const GET_DRIVERS = "GET_DRIVERS";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const GO_BACK_HISTORY = "GO_BACK_HISTORY";
/* coffee connect */

export const GET_CHANNEL_LIST = "GET_CHANNEL_LIST";

//             ------ Stats ------
export const GET_CC_STATS = "GET_STATS";
export const GET_CC_ACTIVITIES = "GET_ACTIVITIES";
export const SET_ACTIVE_STATS_DATE = "SET_ACTIVE_STATS_DATE";
export const UPDATE_CC_STATS = "UPDATE_CC_STATS";
export const SET_SHOW_LOADER = "SET_SHOW_LOADER";

/* Homepage Analytics */

export const GET_HOMEPAGE_CONFIG = "GET_HOMEPAGE_CONFIG";
export const SET_HOMEPAGE_ACTIVE_SIDEBAR = "SET_HOMEPAGE_ACTIVE_SIDEBAR";
export const GET_HOMEPAGE_INSIGHTS = "GET_HOMEPAGE_INSIGHTS";

/* Homepage Analytics */

export const GET_ANALYTICS_CONFIG = "GET_ANALYTICS_CONFIG";
export const SET_ANALYTICS_INSIGHTS = "SET_ANALYTICS_INSIGHTS";

/* Deployment Info */
export const GET_FRONTEND_BRANCH = "GET_FRONTEND_BRANCH";
export const GET_BACKEND_BRANCH = "GET_BACKEND_BRANCH";
export const GET_DEPLOYMENT_VISIBILITY = "GET_DEPLOYMENT_VISIBILITY";

/* Survey Audience Page */
export const GET_SURVEY_EMPLOYEE_LIST = "GET_SURVEY_EMPLOYEE_LIST";
export const SET_SURVEY_EMPLOYEE_LIST_LOADING =
  "SET_SURVEY_EMPLOYEE_LIST_LOADING";
export const UPDATE_USER_NPS_DETAILS = "UPDATE_USER_NPS_DETAILS";

/* Perf Review Cycle Creation */
export const TOGGLE_IS_ADDING_REVIEWEE = "TOGGLE_IS_ADDING_REVIEWEE";
export const UPDATE_REVIEW_FORMS = "UPDATE_REVIEW_FORMS";
export const SET_REVIEW_FORMS = "SET_REVIEW_FORMS";
export const UPDATE_REVIEWEE = "UPDATE_REVIEWEE";
export const ADD_REVIEW_FORM = "ADD_REVIEW_FORM";

/* CSV Box */
export const ADD_CSV_BOX_CONFIG = "ADD_CSV_BOX_CONFIG";

/* Trackers */
export const SET_OPEN_REPLAY_TRACKER = "SET_OPEN_REPLAY_TRACKER";

/* Notification Integrations */
export const MICROSOFT_TEAMS_INTEGRATION_STATUS =
  "MICROSOFT_TEAMS_INTEGRATION_STATUS";
export const REMOVE_MICROSOFT_TEAMS_INTEGRATION =
  "REMOVE_MICROSOFT_TEAMS_INTEGRATION";
