import {
  LOGIN_SUCCESS,
  GET_USER_DETAILS,
  LOGIN_FAILURE,
  USER_SWITCHED,
  LOGIN_ERROR_CLEAR,
  UPDATE_CALENDAR_ACCESS_CHECK,
  UPDATE_ACCOUNT_SETTINGS,
  UPDATE_USER_DETAILS,
  UPDATE_GOAL_SETTINGS,
  UPDATE_SSO_SETTING_STATUS,
  UPDATE_USER_NPS_DETAILS,
  UPDATE_USER_PLANNING_YEAR,
  UPDATE_COMPANY_1ON1_SETTINGS,
} from "../actions/constants";

const initialState = {
  isLoggedIn: false,
  accountId: null,
  employeeName: null,
  superAdmin: false,
  userId: null,
  switchedUser: false,
  companyName: null,
  companySize: null,
  recordSessions: false,
  show_engagement: false,
  show_my_feedback: false,
  show_team_feedback: false,
  manager_details: null,
  loginCount: 0,
  selfSignedUp: false,
  directReportsCount: 0,
  status: null,
  message: null,
  employee_details: null,
  admin_permissions: null,
  timezone: null,
  timezoneAbbrev: null,
  upcoming_one_on_one_id: null,
  reportees: [],
  userEmail: null,
  employeeFirstName: null,
  onboardingDetails: null,
  employeeId: null,
  engagementSheetDetails: null,
  calendarAccessProvided: false,
  calendarProvider: null,
  calendarAccess: false,
  ceo: null,
  company_timezone_offset: null,
  messagingClient: null,
  accountRunCoffeeConnect: null,
  ccChannel: null,
  employeeRunCoffeeConnect: null,
  metabaseToken: null,
  metabaseCloudKey: null,
  goalSettings: null,
  isGoalNpsEnabled: false,
  isBizNpsEnabled: false,
  empStatusReviewInfo: null,
  companyOneOnOneSettings: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const {
        dashboard_auth_token,
        calendarProvider,
        calendarAccess,
        timezone,
        employeeName,
        featureFlags,
        reportees,
        accountId,
        manager_details,
        employee_details,
        admin_permissions,
        isLoggedIn,
        superAdmin,
        show_engagement,
        show_my_feedback,
        show_team_feedback,
        companyName,
        companySize,
        userId,
        recordSessions,
        loginCount,
        selfSignedUp,
        directReportsCount,
        upcoming_one_on_one_id,
        timezoneAbbrev,
        onboarding_details,
        engagementSheetDetails,
        ceo,
        companyTimezone,
        company_timezone_offset,
        messagingClient,
        accountRunCoffeeConnect,
        ccChannel,
        employeeRunCoffeeConnect,
        metabaseToken,
        goalSettings,
        show_okr_sheet,
        signupDate,
        levelInCompany,
        hasCreatedGoals,
        lastGoalCreatedAt,
        companyCreatedAt,
        companyPhoneNumber,
        companyCommunicationTool,
        productOfInterest,
        trialObjective,
        slackIntegrated,
        onPaidSubscription,
        teamInvited,
        useImprovedBizReviews,
        ssoEnabled,
        enable_kpi,
        okrUpdatesEnabled,
        qualitativeCommentLines,
        metabaseCloudKey,
        empStatusReviewInfo,
        companyOneOnOneSettings,
        products,
        connected_workspace_user_id,
        disableMonthlyGoalCycles,
        eligibleForOrgChart,
        reportsTab,
        accountSettings,
      } = action.payload;
      const newState = Object.assign({}, state);
      newState.isLoggedIn = isLoggedIn;
      newState.employeeName = employeeName;
      newState.accountId = accountId;
      newState.userId = userId;
      newState.superAdmin = superAdmin;
      newState.show_engagement = show_engagement;
      newState.show_my_feedback = show_my_feedback;
      newState.show_team_feedback = show_team_feedback;
      newState.employee_details = employee_details;
      newState.admin_permissions = admin_permissions;
      newState.manager_details = manager_details;
      newState.companyName = companyName;
      newState.companySize = companySize;
      newState.selfSignedUp = selfSignedUp;
      newState.loginCount = loginCount;
      newState.recordSessions = recordSessions;
      newState.directReportsCount = directReportsCount;
      newState.status = null;
      newState.message = null;
      newState.timezone = timezone;
      newState.timezoneAbbrev = timezoneAbbrev;
      newState.upcoming_one_on_one_id = upcoming_one_on_one_id;
      newState.userEmail = employee_details.user.email;
      newState.reportees = reportees;
      newState.employeeFirstName = employee_details.first_name;
      newState.onboardingDetails = onboarding_details;
      newState.employeeId = employee_details.id;
      newState.engagementSheetDetails = engagementSheetDetails;
      newState.calendarProvider = calendarProvider;
      newState.calendarAccess = calendarAccess;
      newState.ceo = ceo;
      newState.companyTimezone = companyTimezone;
      newState.company_timezone_offset = company_timezone_offset;
      newState.messagingClient = messagingClient;
      newState.accountRunCoffeeConnect = accountRunCoffeeConnect;
      newState.ccChannel = ccChannel;
      newState.employeeRunCoffeeConnect = employeeRunCoffeeConnect;
      // FIXME This looks really shitty. Isn't there a better way to do this?
      newState.metabaseToken = metabaseToken;
      newState.metabaseCloudKey = metabaseCloudKey;
      newState.goalSettings = goalSettings;
      newState.show_okr_sheet = show_okr_sheet;
      newState.signupDate = signupDate;
      newState.levelInCompany = levelInCompany;
      newState.hasCreatedGoals = hasCreatedGoals;
      newState.lastGoalCreatedAt = lastGoalCreatedAt;
      newState.companyCreatedAt = companyCreatedAt;
      newState.companyPhoneNumber = companyPhoneNumber;
      newState.companyCommunicationTool = companyCommunicationTool;
      newState.productOfInterest = productOfInterest;
      newState.trialObjective = trialObjective;
      newState.slackIntegrated = slackIntegrated;
      newState.onPaidSubscription = onPaidSubscription;
      newState.teamInvited = teamInvited;
      newState.featureFlags = featureFlags;
      newState.useImprovedBizReviews = useImprovedBizReviews;
      newState.ssoEnabled = ssoEnabled;
      newState.enable_kpi = enable_kpi;
      newState.okrUpdatesEnabled = okrUpdatesEnabled;
      newState.qualitativeCommentLines = qualitativeCommentLines;
      newState.empStatusReviewInfo = empStatusReviewInfo;
      newState.companyOneOnOneSettings = companyOneOnOneSettings;
      newState.products = products;
      newState.connectedWorkspaceUserId = connected_workspace_user_id;
      newState.disableMonthlyGoalCycles = disableMonthlyGoalCycles;
      newState.eligibleForOrgChart = eligibleForOrgChart;
      newState.reportsTab = reportsTab;
      newState.accountSettings = accountSettings;

      window.localStorage.setItem("dashboard_auth_token", dashboard_auth_token);
      return newState;
    }
    case LOGIN_FAILURE: {
      const { isLoggedIn, status, message, lastTriedEmail } = action.payload;
      const newState = Object.assign({}, state);
      newState.isLoggedIn = isLoggedIn;
      newState.status = status;
      newState.message = message;
      newState.lastTriedEmail = lastTriedEmail;
      return newState;
    }
    case LOGIN_ERROR_CLEAR: {
      const newState = Object.assign({}, state);
      newState.status = null;
      newState.message = null;
      return newState;
    }
    case GET_USER_DETAILS: {
      const {
        isLoggedIn,
        timezone,
        timezoneAbbrev,
        calendarProvider,
        calendarAccess,
        employeeName,
        reportees,
        accountId,
        superAdmin,
        employee_details,
        admin_permissions,
        manager_details,
        show_engagement,
        show_my_feedback,
        show_team_feedback,
        userId,
        email,
        companyName,
        companySize,
        chatwootHash,
        recordSessions,
        loginCount,
        selfSignedUp,
        directReportsCount,
        upcoming_one_on_one_id,
        onboarding_details,
        engagementSheetDetails,
        ceo,
        companyTimezone,
        company_timezone_offset,
        messagingClient,
        accountRunCoffeeConnect,
        ccChannel,
        employeeRunCoffeeConnect,
        metabaseToken,
        metabaseCloudKey,
        planning_year,
        goalSettings,
        show_okr_sheet,
        signupDate,
        levelInCompany,
        hasCreatedGoals,
        lastGoalCreatedAt,
        companyCreatedAt,
        companyPhoneNumber,
        companyCommunicationTool,
        productOfInterest,
        trialObjective,
        slackIntegrated,
        onPaidSubscription,
        teamInvited,
        featureFlags,
        useImprovedBizReviews,
        ssoEnabled,
        enable_kpi,
        okrUpdatesEnabled,
        qualitativeCommentLines,
        empStatusReviewInfo,
        companyOneOnOneSettings,
        products,
        connected_workspace_user_id,
        disableMonthlyGoalCycles,
        eligibleForOrgChart,
        reportsTab,
        accountSettings,
      } = action.payload;

      const newState = Object.assign({}, state);
      newState.isLoggedIn = isLoggedIn;
      newState.employeeName = employeeName;
      newState.accountId = accountId;
      newState.superAdmin = superAdmin;
      newState.show_engagement = show_engagement;
      newState.show_my_feedback = show_my_feedback;
      newState.show_team_feedback = show_team_feedback;
      newState.employee_details = employee_details;
      newState.admin_permissions = admin_permissions;
      newState.userId = userId;
      newState.email = email;
      newState.selfSignedUp = selfSignedUp;
      newState.loginCount = loginCount;
      newState.companyName = companyName;
      newState.companySize = companySize;
      newState.chatwootHash = chatwootHash;
      newState.recordSessions = recordSessions;
      newState.manager_details = manager_details;
      newState.directReportsCount = directReportsCount;
      newState.timezone = timezone;
      newState.timezoneAbbrev = timezoneAbbrev;
      newState.upcoming_one_on_one_id = upcoming_one_on_one_id;
      newState.reportees = reportees;
      newState.engagementSheetDetails = engagementSheetDetails;
      newState.calendarProvider = calendarProvider;
      newState.calendarAccess = calendarAccess;
      newState.ceo = ceo;
      newState.companyTimezone = companyTimezone;
      newState.company_timezone_offset = company_timezone_offset;
      newState.messagingClient = messagingClient;
      newState.accountRunCoffeeConnect = accountRunCoffeeConnect;
      newState.ccChannel = ccChannel;
      newState.employeeRunCoffeeConnect = employeeRunCoffeeConnect;
      newState.planning_year = planning_year;
      newState.metabaseToken = metabaseToken;
      newState.metabaseCloudKey = metabaseCloudKey;
      newState.goalSettings = goalSettings;

      newState.status = null;
      newState.message = null;

      newState.onboardingDetails = onboarding_details;

      newState.userEmail = employee_details.user.email;
      newState.employeeFirstName = employee_details.first_name;
      newState.employeeId = employee_details.id;
      newState.show_okr_sheet = show_okr_sheet;
      newState.signupDate = signupDate;
      newState.levelInCompany = levelInCompany;
      newState.hasCreatedGoals = hasCreatedGoals;
      newState.lastGoalCreatedAt = lastGoalCreatedAt;
      newState.companyCreatedAt = companyCreatedAt;
      newState.companyPhoneNumber = companyPhoneNumber;
      newState.companyCommunicationTool = companyCommunicationTool;
      newState.productOfInterest = productOfInterest;
      newState.trialObjective = trialObjective;
      newState.slackIntegrated = slackIntegrated;
      newState.onPaidSubscription = onPaidSubscription;
      newState.teamInvited = teamInvited;
      newState.featureFlags = featureFlags;
      newState.useImprovedBizReviews = useImprovedBizReviews;
      newState.ssoEnabled = ssoEnabled;
      newState.enable_kpi = enable_kpi;
      newState.okrUpdatesEnabled = okrUpdatesEnabled;
      newState.qualitativeCommentLines = qualitativeCommentLines;
      newState.empStatusReviewInfo = empStatusReviewInfo;
      newState.companyOneOnOneSettings = companyOneOnOneSettings;
      newState.products = products;
      newState.connectedWorkspaceUserId = connected_workspace_user_id;
      newState.disableMonthlyGoalCycles = disableMonthlyGoalCycles;
      newState.eligibleForOrgChart = eligibleForOrgChart;
      newState.reportsTab = reportsTab;
      newState.accountSettings = accountSettings;
      return newState;
    }
    case USER_SWITCHED: {
      const {
        isLoggedIn,
        timezone,
        timezoneAbbrev,
        employeeName,
        reportees,
        accountId,
        employee_details,
        admin_permissions,
        manager_details,
        superAdmin,
        show_engagement,
        show_my_feedback,
        show_team_feedback,
        dashboard_auth_token,
        switchedUser,
        companyName,
        companySize,
        userId,
        recordSessions,
        upcoming_one_on_one_id,
        onboarding_details,
        ceo,
        companyTimezone,
        company_timezone_offset,
        messagingClient,
        accountRunCoffeeConnect,
        ccChannel,
        employeeRunCoffeeConnect,
        featureFlags,
        connected_workspace_user_id,
        disable_department_creation,
        account_setting_id,
      } = action.payload;
      const newState = Object.assign({}, state);
      newState.isLoggedIn = isLoggedIn;
      newState.employeeName = employeeName;
      newState.accountId = accountId;
      newState.userId = userId;
      newState.superAdmin = superAdmin;
      newState.show_engagement = show_engagement;
      newState.show_my_feedback = show_my_feedback;
      newState.show_team_feedback = show_team_feedback;
      newState.employee_details = employee_details;
      newState.admin_permissions = admin_permissions;
      newState.switchedUser = switchedUser;
      newState.companyName = companyName;
      newState.companySize = companySize;
      newState.recordSessions = recordSessions;
      newState.manager_details = manager_details;
      newState.status = null;
      newState.message = null;
      newState.timezone = timezone;
      newState.timezoneAbbrev = timezoneAbbrev;
      newState.upcoming_one_on_one_id = upcoming_one_on_one_id;
      newState.userEmail = employee_details.user.email;
      newState.reportees = reportees;
      newState.employeeFirstName = employee_details.first_name;
      newState.onboardingDetails = onboarding_details;
      newState.employeeId = employee_details.id;
      newState.ceo = ceo;
      newState.companyTimezone = companyTimezone;
      newState.company_timezone_offset = company_timezone_offset;
      newState.messagingClient = messagingClient;
      newState.accountRunCoffeeConnect = accountRunCoffeeConnect;
      newState.ccChannel = ccChannel;
      newState.employeeRunCoffeeConnect = employeeRunCoffeeConnect;
      newState.featureFlags = featureFlags;
      newState.connectedWorkspaceUserId = connected_workspace_user_id;
      newState.disable_department_creation = disable_department_creation;
      newState.account_setting_id = account_setting_id;

      window.localStorage.setItem("dashboard_auth_token", dashboard_auth_token);
      return newState;
    }
    case UPDATE_CALENDAR_ACCESS_CHECK: {
      const newState = Object.assign({}, state, action.payload);
      return newState;
    }
    case UPDATE_ACCOUNT_SETTINGS: {
      const newState = Object.assign({}, state, action.payload);
      return newState;
    }
    case UPDATE_USER_DETAILS: {
      const newState = Object.assign({}, state, action.payload);
      return newState;
    }
    case UPDATE_USER_NPS_DETAILS: {
      const newState = Object.assign({}, state, action.payload);
      return newState;
    }
    case UPDATE_USER_PLANNING_YEAR: {
      const newState = { ...state, planning_year: action.payload };
      return newState;
    }
    case UPDATE_GOAL_SETTINGS: {
      return {
        ...state,
        goalSettings: {
          ...state.goalSettings,
          ...action.payload,
        },
      };
    }
    case UPDATE_SSO_SETTING_STATUS: {
      const newState = Object.assign({}, state, action.payload);
      return newState;
    }
    case UPDATE_COMPANY_1ON1_SETTINGS: {
      return {
        ...state,
        companyOneOnOneSettings: action.payload,
      };
    }
    default:
      return state;
  }
};

export default user;
