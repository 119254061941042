const initialState = {
  isFetchingQuotaData: false,
  isFetchingCalibrationTableData: false,
  isFetchingCalibrateeScoreLog: false,
  quotaData: {},
  calibrationTableData: {},
  calibrationTableDataFilters: {},
  calibrateeScoreLog: {},
};

const quotaCalibration = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_IS_FETCHING_QUOTA_DATA": {
      return {
        ...state,
        isFetchingQuotaData: !state.isFetchingQuotaData,
      };
    }
    case "TOGGLE_IS_FETCHING_CALIBRATION_TABLE_DATA": {
      return {
        ...state,
        isFetchingCalibrationTableData: !state.isFetchingCalibrationTableData,
      };
    }
    case "TOGGLE_IS_FETCHING_REVIEWEE_SCORE_LOG": {
      return {
        ...state,
        isFetchingCalibrateeScoreLog: !state.isFetchingCalibrateeScoreLog,
      };
    }
    case "SET_CALIBRATION_TABLE_DATA": {
      const { data, pageNumber } = action.payload;
      return {
        ...state,
        calibrationTableData: {
          ...state.calibrationTableData,
          [pageNumber]: data,
        },
      };
    }
    case "SET_QUOTA_DATA": {
      return {
        ...state,
        quotaData: action.payload,
      };
    }
    case "SET_CALIBRATION_TABLE_DATA_FILTERS": {
      return {
        ...state,
        calibrationTableDataFilters: action.payload,
      };
    }
    case "SET_CALIBRATEE_SCORE_LOG": {
      const { data, calibrateeId, pageNumber } = action.payload;
      return {
        ...state,
        calibrateeScoreLog: {
          ...state.calibrateeScoreLog,
          [calibrateeId]: {
            ...state.calibrateeScoreLog[calibrateeId],
            [pageNumber]: data,
          },
        },
      };
    }
    case "UPDATE_REVIEWEE_CALIBRATED_SCORE": {
      const { calibrateeId, currentPage, score } = action.payload;

      return {
        ...state,
        calibrationTableData: {
          ...state.calibrationTableData,
          [currentPage]: {
            ...state.calibrationTableData[currentPage],
            calibratees: {
              ...state.calibrationTableData[currentPage]?.calibratees,
              [calibrateeId]: {
                ...state.calibrationTableData[currentPage]?.calibratees[
                  calibrateeId
                ],
                calibrated_score: score,
              },
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

export default quotaCalibration;
