import nominatePeer from "./NominatePeer";
import reviewCycle from "./ReviewCycle";
import approvePeers from "./ApprovePeers";
import approveGoals from "./ApproveGoals";
import writeReviews from "./WriteReviews";
import manageReviews from "./ManageReviews";
import createReviewCycle from "./CreateReviewCycle";
import reviewCycleActions from "./ReviewCycleActions";
import quotaCalibration from "store/PerformanceReviews/QuotaCalibration/reducer";
import { combineReducers } from "redux";

const performanceReviews = combineReducers({
  nominatePeer,
  reviewCycle,
  approvePeers,
  approveGoals,
  writeReviews,
  manageReviews,
  createReviewCycle,
  reviewCycleActions,
  quotaCalibration,
});

export default performanceReviews;
