import {
  GET_NOTES,
  ADD_NOTES,
  ADD_TEMPLATE_POINT_TO_SHARED_NOTES,
  UPDATE_SHARED_NOTES,
  UPDATE_USER_PRIVATE_NOTES,
} from "../../actions/constants";

const initialState = {};

const oneOnOneNotes = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTES: {
      const newState = Object.assign({}, state, {
        ...action.payload,
      });
      return newState;
    }

    case ADD_NOTES: {
      const newState = Object.assign({}, state, {
        sharedNotes: action.payload.sharedNotes,
        userPrivatenotes: action.payload.userPrivatenotes,
      });
      return newState;
    }

    case ADD_TEMPLATE_POINT_TO_SHARED_NOTES: {
      const newState = Object.assign({}, state, {
        ...action.payload,
      });
      return newState;
    }

    case UPDATE_USER_PRIVATE_NOTES: {
      const { oneOnOneId } = action.payload;
      const newState = {
        ...state,
        [oneOnOneId]: {
          sharedNotes: {
            ...state[oneOnOneId].sharedNotes,
          },
          userPrivatenotes: {
            ...state[oneOnOneId].userPrivatenotes,
            notes_json_text: action.payload.updatedNotes,
          },
          templateTalkingPoint: state[oneOnOneId].templateTalkingPoint,
        },
      };
      return newState;
    }

    case UPDATE_SHARED_NOTES: {
      const { oneOnOneId } = action.payload;
      const newState = {
        ...state,
        [oneOnOneId]: {
          ...state[oneOnOneId],
          sharedNotes: {
            ...state[oneOnOneId].sharedNotes,
            notes_json_text: action.payload.updatedNotes,
          },
        },
      };
      return newState;
    }

    default:
      return state;
  }
};

export default oneOnOneNotes;
